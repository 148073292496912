<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-page-header @back="goBack" ></el-page-header>
      <el-breadcrumb-item >我的班级</el-breadcrumb-item>
      <el-breadcrumb-item>课程资料</el-breadcrumb-item>
      <el-breadcrumb-item>{{title}}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-tabs type="card"
             v-model="activeName">
      <el-tab-pane :label="title"
                   name="0">
        <el-table :data="solution"
                  border
                  style="width: 100%"
                  :row-class-name="tableRowClassName">
          <el-table-column prop="LessonNo"
                           fixed
                           label="课程讲次"
                           align="center"
                           width="200">
          </el-table-column>
          <el-table-column prop="keqian"
                           label="课前预习"
                           width="200">
            <template slot-scope="scope">
              <div class="handle">
                <a v-show="scope.row.BeforeClassFile=='1'"
                   @click="gomaterial(scope.row,'BeforeClassFile','课前资料')">课前资料</a>
                <a v-show="scope.row.BeforeClassVideo=='1'"
                   @click="govideo(scope.row,'BeforeClassVideo','课前视频')">课前视频</a>
                <p v-show="scope.row.BeforeClassFile=='0' && scope.row.BeforeClassVideo=='0'">无</p>                   
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="kezhong"
                           label="课中ing"
                           width="320">
            <template slot-scope="scope">
              <div class="handle">
                <a v-show="scope.row.Analysis=='1'"
                   @click="gomaterial(scope.row,'Analysis','课堂解析')">课堂解析</a>
                <a v-show="scope.row.ClassSubmit=='1'"
                   @click="goupload(scope.row,'Analysis','堂测提交')">堂测提交</a>
                <a v-show="scope.row.ClassResult=='1'"
                   @click="goresult(scope.row,'Analysis','堂测批改结果')">堂测批改结果</a>
                <p v-show="scope.row.Analysis=='0' && scope.row.ClassSubmit=='0'&& scope.row.ClassResult=='0'">无</p>                   
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="kehou"
                           label="课后巩固"
                           width="550">
            <template slot-scope="scope">
              <div class="handle">
                <a v-show="scope.row.AfterClassFile=='1'"
                   @click="gomaterial(scope.row,'AfterClassFile','课后资料')">课后资料</a>
                <a v-show="scope.row.AfterClassVideo=='1'"
                   @click="govideo(scope.row,'AfterClassVideo','课后视频')">课后视频</a>
                <a v-show="scope.row.Expand=='1'"
                   @click="gomaterial(scope.row,'Expand','拓展练习')">拓展练习</a>
                <a v-show="scope.row.ExpandSubmit=='1'"
                   @click="goupload(scope.row,'Expand','拓展练习提交')">拓展练习提交</a>
                <a v-show="scope.row.ExpandResult=='1'"
                   @click="goresult(scope.row,'Expand','拓展练习批改结果')">拓展练习批改结果</a>
                <p v-show="scope.row.AfterClassFile=='0' && scope.row.AfterClassVideo=='0'&& scope.row.Expand=='0' && scope.row.ExpandSubmit=='0'&& scope.row.ExpandResult=='0'">无</p>              
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { get_classes_materials } from '@/api/classesinfo'
export default {
  name: 'class_coursemater',
  data () {
    return {
      activeName: '0',
      DBType: '',
      OprId: '',
      classesinfo: {},
      solution: [],
      title: '',
      userid:this.$store.state.user.stu_user_info[0].id  
    };
  },
  created () {
    var query = sessionStorage.getItem('stu_class_query')? JSON.parse(sessionStorage.getItem('stu_class_query')):{}
    this.DBType = query.DBType || null;
    this.OprId = query.OprId || null;
    this.$nextTick(() => {
      this.getInit()
    })
  },
  methods: {
    goBack(){
      this.$router.go(-1)
    },
    async getInit () {
      let prames = {
        DBType: this.DBType,
        OprId: this.OprId,
      }
      let res = await get_classes_materials(prames)
      if (res.flag == '1') {
        let classesinfo = res.datainfo.classesinfo;
        this.solution = res.datainfo.solution;
        let SubSeason=classesinfo.SubSeason&&classesinfo.SubSeason!=null?classesinfo.SubSeason:'';
        this.title = classesinfo.Year + classesinfo.Season + SubSeason + classesinfo.Subject + classesinfo.GradeStart + classesinfo.ClassType;
        this.classesinfo = classesinfo;
      }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }
    },
    //奇偶数背景色
    tableRowClassName ({ rowIndex }) {
      if (rowIndex % 2 == 0) {
        return 'even';
      } else if (rowIndex % 2 == 1) {
        return 'odd';
      }
      return '';
    },
    handleClick (tab) {
      this.activeName = tab.index
    },
    //课程资料下载查看
    gomaterial (item,FileType,type) {
      let prames={
           LessonNo:item.LessonNo,
          SolutionID: item.SolutionID,
          type: type,
          title:this.title,
          FileType:FileType,
          userid: this.userid
      }
      this.$router.push({
        path: '/coursemater/material',
      })
      sessionStorage.setItem('stu_coursemater_query',JSON.stringify(prames) )   
    },
    //课程视频
    govideo (item,FileType,type) {
      let prames={
           LessonNo:item.LessonNo,
          SolutionID: item.SolutionID,
          type: type,
          title:this.title,
          FileType:FileType,
          userid: this.userid
      }
      this.$router.push({
        path: '/coursemater/coursevideo',
      })   
      sessionStorage.setItem('stu_coursemater_query',JSON.stringify(prames) )      
    },
     //拓展或堂测上传
    goupload (item,FileType, type) {
      let prames={
          type: type,
          DBType:this.classesinfo.DBType,
          OprId:this.classesinfo.OprId,
          LessonID:item.LessonID,
          UploadType:FileType,
          title:this.title,
          userid: this.userid,   
          LessonNo:item.LessonNo,    
      }
      this.$router.push({
        path: '/coursemater/courseupload',
      })
      sessionStorage.setItem('stu_coursemater_query',JSON.stringify(prames) )      
    },
    //拓展或堂测批改结果页
    goresult (item, FileType, type) {
      let prames={
          type: type,
          DBType:this.classesinfo.DBType,
          OprId:this.classesinfo.OprId,
          LessonID:item.LessonID,
          UploadType:FileType,
          title:this.title,
          userid: this.userid
      }
      this.$router.push({
        path: '/coursemater/courseuploadresult',
      })
      sessionStorage.setItem('stu_coursemater_query',JSON.stringify(prames) ) 
    }
  },

}
</script>
<style scoped lang="scss">
.handle {
  justify-content: start;
  a {
    margin-right: 20px;
    text-decoration: underline;
    color: #7276fe;
    cursor: pointer;
  }
}
</style>